import React, { Component } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as contentful from 'contentful'
import { GroupPageLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'
import { pagesGroupChildTypes } from './../../utils/url'
import { PreviewHelper } from '../../helpers'
import map from 'lodash/map'

class GroupPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            group: null,
            sitemap: []
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('pagesGroup', this.props.location.search).then(entry => {
            const client = contentful.createClient({
                space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
                accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN,
                environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
                host: 'preview.contentful.com',
            })

            return client
                .getEntries({
                    links_to_entry: entry.sys.id,
                    locale: 'en-NZ',
                    'sys.contentType.sys.id[in]': pagesGroupChildTypes.join(','),
                    include: 1,
                })
                .then(
                    data => {
                        return this.initializeGroupPage(entry, data)
                    },
                    () => {
                        console.error('Cannot load group preview')
                        return null
                    }
                )
        })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    } 

    initializeGroupPage(entry, data) {
        const page__generic = []
        const page__widgets = []
        const pages_group = []

        data.items.forEach(item => {
            let arr = [],
                __typename = ''
            if (item.sys.contentType.sys.id === 'pagesGroup') {
                arr = pages_group
                __typename = 'ContentfulPageGroup'
            } else if (item.sys.contentType.sys.id === 'genericPage') {
                arr = page__generic
                __typename = 'ContentfulPageGeneric'
            } else if (item.sys.contentType.sys.id === 'widgetsPage') {
                arr = page__widgets
                __typename = 'ContentfulPageWidgets'
            }

            const summary = item.fields.summary ? { summary: item.fields.summary} : undefined

            arr.push({
                __typename,
                ...item.fields,
                summary
            })
        })

        const group = {
            __typename: 'ContentfulPageGroup',
            contentful_id: entry.sys.id,
            page__generic,
            page__widgets,
            pages_group,
            ...entry.fields,
            headerImage: entry.fields.headerImage ? entry.fields.headerImage.fields : null,
            image: entry.fields.image ? entry.fields.image.fields : null,
            summary: {
                summary: entry.fields.summary,
            },
            groups: entry.fields.groups ? entry.fields.groups.map(group => group.fields) : [],
            sections: map(entry.fields.sections, section => ({
                ...section.fields,
                widgets: map(section.fields.widgets, widget => PreviewHelper.parse(widget))
            }))
        }

        this.setState({
            group,
        })
    }

    render() {
        const { group, sitemap } = this.state

        return !this.props.loading && group ? <GroupPageLayout preview={true} group={group} sitemap={sitemap} /> : <></>
    }
}

export default withPreview(GroupPagePreview)
